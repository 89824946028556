html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
html {
  box-sizing: border-box;
  font-family: 'Poppins';
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
button {
    display: inline-block;
    border: none;
    padding: 0 0;
    margin: 0;
    text-decoration: none;
    color: #5F5F5F;
    background: inherit;
    font-family: sans-serif;
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, 
                transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
}
input {
    background: #FFFFFF;
    border: 0.2px solid #4B78B2;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 17px 12px 14px 12px;
    font-weight: 400;
    font-size: 16px;
    width: 100%;
}

button:focus {
    outline: 0px solid #fff;
    outline-offset: -4px;
}

button:active {
    transform: scale(0.99);
}

.dashboard-icon {
    background-image: url("../public/images/dashboard.svg");
}

.documentation-icon {
    background-image: url("../public/images/documentation.svg");
}

.benefits-icon {
    background-image: url("../public/images/benefits.svg");
}

.active .dashboard-icon {
    background-image: url("../public/images/dashboard-active.svg");
}

.active .documentation-icon {
    background-image: url("../public/images/documentation-active.svg");
}

.active .benefits-icon {
    background-image: url("../public/images/benefits-active.svg");
}

.fire-background:before {
  background-image: url("../public/images/fire-background.svg");
}

.manure-background:before {
  background-image: url("../public/images/manure-background.svg");
}

.cow-background:before {
  background-image: url("../public/images/cow-background.svg");
}

.search-icon {
    background-image: url("../public/images/search.svg");
}

.ms-icon {
    background-image: url("../public/images/ms-pictogram.svg");
    width: 20px;
    height: 20px;
}

h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #2C2C2C;
}

.title {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #2C2C2C;
}

.logout-icon {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    display: inline-block;
    background-image: url("../public/images/logout.svg");
    vertical-align: text-bottom;
}

.hidden-scroll {
    overflow: hidden;
}

.benefit-item {
    display: flex;
    align-items: center;
}
.house-icon {
    background-image: url("../public/images/house.svg");
}
.car-icon {
    background-image: url("../public/images/car.svg");
}

.xlsx-icon {
    background-image: url("../public/images/xlsx.svg");
}
.pdf-icon {
    background-image: url("../public/images/pdf.svg");
}
.docx-icon {
    background-image: url("../public/images/docx.svg");
}
.jpg-icon {
    background-image: url("../public/images/jpg.svg");
}

.bin-icon {
    background-image: url("../public/images/bin.png");
}

.document-icon {
    width: 60px;
    height: 60px;
}

.grey-font {
    color: #757575;
}

.grey {
    background: #D9D9D9;
}

.green {
    background: #078144;
}

.text-right {
    text-align: right;
}

.m-r-5 {
    margin-right: 5px !important;
}

.m-t-5 {
    margin-top: 5px !important;
}

.m-t-10 {
    margin-top: 10px !important;
}

.m-t-15 {
    margin-top: 15px !important;
}

.m-t-20 {
    margin-top: 20px !important;
}

.m-t-25 {
    margin-top: 25px !important;
}

.m-t-30 {
    margin-top: 30px !important;
}

.m-t-35 {
    margin-top: 35px !important;
}

.m-t-40 {
    margin-top: 40px !important;  
}

.m-b-20 {
    margin-bottom: 20px !important;
}

.m-b-15 {
    margin-bottom: 15px !important;
}

.m-b-10 {
    margin-bottom: 10px !important;
}

.m-b-5 {
    margin-bottom: 5px !important;
}

.chart-period-button {
    display: flex;
}
.loading-space {
    height: 4px;
}