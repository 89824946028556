thead.default-header tr {
    background-color: #078144;
    color: #FFFFFF;
}

thead.default-header tr th {
    padding: 16px 8px;
    background-color: #078144;
    border-left: 1px solid #078144;
    border-right: 1px solid #078144;
    white-space: nowrap;
}

td {
    white-space: nowrap;
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
}

tr.default-row {
    box-shadow: 2px 4px 4px rgba(75, 120, 178, 0.25);
}

td:first-child {
    border-radius: 5px 0 0 5px;
    border-top: thin solid #000000;
    border-left: thin solid #000000;
    border-bottom: thin solid #000000;
}

td:last-child {
    border-radius: 0 5px 5px 0;
    border-top: thin solid #000000;
    border-right: thin solid #000000;
    border-bottom: thin solid #000000;
}

tr.default-row:nth-child(even) {
    background: rgba(146, 196, 71, 0.25);
}

table.default {
    width: 100%;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    border-collapse: separate; 
    border-spacing: 0 10px;
}

td.default-cell {
    padding: 14px 10px;
    text-align: center;
}