.loading {
    height: 4px;
    background-color: #078144;
    width: 100%;
    overflow: hidden;
}

.loading > div {
    width: 100%;
    height: 100%;
    background-color: #DAE9BF;
    animation: indeterminateAnimation 1s infinite linear;
    transform-origin: 0% 50%;
}
  
@keyframes indeterminateAnimation {
    0% {
        transform:  translateX(0) scaleX(0);
    }
    40% {
        transform:  translateX(0) scaleX(0.4);
    }
    100% {
        transform:  translateX(100%) scaleX(0.5);
    }
}