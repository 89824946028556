div.green-panel {
    background: rgba(7, 129, 68, 0.7);
    text-align: center;
    padding: 31px 5px 32px 5px;
    color: #FFFFFF;
    font-size: 16px;
}

.benefit-icon {
    width: 60px;
    height: 60px;
}

.benefit-item {
    padding: 0 5px;
    min-width: 700px;
}

.benefit-item .text {
    font-size: 20px;
    font-weight: 600;
}

.benefit-item .value {
    font-weight: 600;
    font-size: 50px;
    color: #078144;
    margin: 0 24px 0 33px;
}

.benefits-list {
    margin-top: 40px;
}

.benefits-list > div {
    margin-top: 20px;
}