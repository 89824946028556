
input {
    box-sizing: border-box;
}

.input {
    position: relative;
}

.input .icon {
    position: absolute;
    width: 22px;
    height: 18px;
    top: 15px;
    left: 20px;
}

input.with-icon {
    padding-left: 55px;
}

input::placeholder {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}

.file-upload .file-names {
    padding: 0 10px;
}

input.not-valid {
    border: 1px solid #FD4233;
}

input.not-valid:focus{
    outline-color: #FD4233;  
    outline-style: solid;
}