.profile {
    padding: 8px 4px;
    display: flex;
    flex: 1;
    flex-direction: row-reverse;
    align-items: center;
    position: relative;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 18px;
    color: #757575;
}

.profile .empty {
    flex: 1;
}

.user-name {
    margin: 0px 5px;
}

.more-info {
    position: relative;
}

.profile .more-info, .profile .user-name {
    line-height: 3;
}

.profile-image {
    background-image: url("../../../public/images/profile.png");
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.more-info-icon {
    background-image: url("../../../public/images/expand_more.svg");
    width: 12px;
    height: 8px;
    margin: 2px 0;
    vertical-align: middle;
}

.profile-menu {
    position: absolute;
    right: 0;
    top: calc(50% + 10px);
    background-color: #ffffff;
    box-shadow: 2px 4px 20px rgba(7, 129, 68, 0.2);
    border-radius: 5px;
    padding: 5px 0;
}

.profile-menu button.light {
    border-radius: unset;
}