
.vertical-menu ul li.menu-item.active {
  background-color: #078144;
}

.vertical-menu ul li.menu-item.active a {
  color: #DAE9BF;
  font-weight: 500;
}

.vertical-menu {
  margin: 34px 16px;
  font-size: 16px;
}

.vertical-menu ul li.menu-item a {
  text-decoration: none;
  color: #078144;
  margin-left: 10px;
  font-weight: 400;
}

.vertical-menu ul li.menu-item {
  margin-top: 16px;
  padding: 16px 22px;
  border-radius: 100px;
}

.menu-icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-repeat: no-repeat;
  vertical-align: text-bottom;
  background-position: 0px 0px;
}