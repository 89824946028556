.ReactModal__Content {
    overflow: inherit !important;
    inset: unset !important;
    top: calc(50% + 40px) !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    padding: 0 !important;
}

.ReactModal__Overlay {
    background-color: inherit !important;
}

.pop-up {
    background: #FFFFFF;
    border-radius: 5px;
    margin: 24px 41px 52px 41px;
    filter: none;
}

.pop-up-top{
    display: flex;
    align-items: center;
    margin: 0px -7px 0 -7px;
}

.pop-up-title {
    flex: 1 1 auto;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    padding-left: 14px;
    text-align: center;
}

.pop-up-close {
    background-image: url('../../../public/images/close.svg');
    width: 14px;
    height: 14px;
}

.pop-up-content {
    padding: 0px 15px 0px 15px;
    margin: 0 5px;
}

.active-modal {
    filter: blur(5px);
}

.scroll-container {
    overflow: auto;
    max-height: calc(100vh - 220px);
}