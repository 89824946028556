.documentation-header {
    display: flex;
    align-items: center;
}

.documentation-header > * {
    flex: 1 1 auto;
}

.document-type-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.document-type-list > div {
    margin-right: 22px;
    margin-top: 15px;
}

.search-bar {
    flex: 0 0 377px;
    margin-left: 22px;
}

.select-farm {
    min-width: 188px;
}

.document-type {
    display: flex;
    flex: 1;
    padding: 0 10px;
    align-items: center;
    min-width: 264px;
}

.document-type-name, .document-info {
    font-weight: 600;
    font-size: 16px;
}

.document-button {
    padding: 0 5px;
}

.document-type-icon {
    background-image: url("../../../public/images/document.svg");
    width: 50px;
    height: 50px;
    margin: 5px 38px 5px 0;
}

.document-list {
    margin-top: 15px;
}

.document-list > div {
    margin-top: 15px;
}

.document {
    display: flex;
    flex: 1;
    padding: 0 5px 0 0;
    align-items: center;
}

.document-info {
    flex: 1 1 auto;
    padding: 0 31px;
}

.document-created {
    margin-top: 10px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
}

.folder {
    display: flex;
    align-items: center;
}

.folder button {
    transition: inherit;
}

.folder.active {
    background-color: #078144;
    color: #FFFFFF;
}

.folder .document-type {
    cursor: pointer;
}

.document-upload {
    display: flex;
}

.form-add-folder {
    width: 348px;
}