
@media (max-width: 400px) {
    .login-grid {
        padding: 10px 5px !important;
    }
}

.login {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 300px;
    padding-left: 10px;
    padding-right: 10px;
}

.login-panel {
    padding: 40px 25px;
}

.login-container {
    min-height: 100vh;
    background: rgba(218, 233, 191, 0.2);
}

.login-grid {
    display: grid;
    align-items: end;
}

.login-grid .logo {
    margin: 0px;
    margin-right: 25px;
    grid-column: 1;
    grid-row: 1 / 3;
}

.login-grid .login-title {
    grid-column: 2;
    grid-row: 1;
}

.login-title > * {
    line-height: 1.5;
}
.login-title .title {
    color: #5F5F5F;
}

.login-grid .login-button {
    grid-column: 2;
    grid-row: 2;
}

.login-icon {
    display: inline-block;
    vertical-align: sub;
    margin-right: 5px;
}

.login-button button{
    padding-left: 25px;
    padding-right: 25px;
}
