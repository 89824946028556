button:disabled,
button[disabled]{
  border: 1px solid #999999 !important;
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

button.default {
    border: 1px solid #078144;
    filter: drop-shadow(2px 4px 4px rgba(7, 129, 68, 0.25));
    background: #FFFFFF;
}

button.danger {
    border: 1px solid #FD4233;
    filter: drop-shadow(2px 4px 4px rgba(7, 129, 68, 0.25));
    background: #FFFFFF;
}

button.default, button.light, button.danger {
    padding: 8px 33px;
    width: 100%;
    border-radius: 5px;
}

button.light {
    filter: drop-shadow(2px 4px 4px rgba(7, 129, 68, 0.25));
    color: #757575;
}

button.light:hover {
    background: #f6f6f6;
}

button.without-shadow {
    filter: none;
}

button.default:hover, button.default.active {
    color: #FFFFFF;
    background: #078144;
}

button.danger:hover {
    color: #FFFFFF;
    background: #FD4233;
}

button.auto {
    width: inherit;
}

button.button-blur {
    color: transparent !important;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.54) !important;
}

button.icon-button {
    padding: 8px;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}
button.icon-button:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.icon-small {
    width: 16px;
    height: 16px;
    background-size: 16px 16px;
}

.icon-medium {
    width: 32px;
    height: 32px;
    background-size: 32px 32px;
}

.file-names {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
}

.button-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #078144;
    margin-top: -12px;
    margin-left: -12px;
}