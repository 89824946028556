@media (max-width: 960px) {
    .grid-carts {
        grid-template-columns: repeat(1, 1fr) !important;
    }
}

@media (max-width: 1240px) and (min-width: 961px) {
    .grid-carts {
        grid-template-columns: repeat(2, 1fr) !important;
    }
}

.grid-carts {
    margin-top: 25px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
}

.cart {
    min-height: 225px;
    position: relative;
    z-index: 1;
    padding: 3px 12px;
}

.cart-background:before {
    width: 80px; 
    height: 80px;  
    content: " ";
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    position: absolute;
    z-index: -1;
    background-repeat: no-repeat;
}

.cart-header {
    display: flex;
    flex: 1;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.cart-title {
    flex: 1;
}

.cart-text {
    font-weight: 700;
}

.text-under-title {
    font-size: 14px;
    font-weight: 600;
}

.chart-icon {
    background-image: url("../../../public/images/chart.svg");
    width: 17px;
    height: 15px;
    margin: 1px 0;
}

.cart-row {
    display: flex;
    flex-wrap: wrap;
    margin-top: 18px;
    padding: 0 0 0 3px;
    color: #5F5F5F;
}

.cart-row.full-size {
    margin-top: 10px;
    padding: 0;
}
.cart-row.line-bar {
    margin-top: 13px;
    margin-bottom: 22px;
    padding: 0;
}

.cart-period {
    flex: 1;
    line-height: 21px;
}

.cart-value {
    font-size: 18px;
    font-weight: 700;
}

.cart-period .period-description {
    font-weight: 500;
    font-size: 12px;
    line-height: 1.3;
}

.flex-default {
    flex: 1 0 auto;
}

.cart-value-percent {
    font-weight: 700;
    font-size: 18px;
}

.cart-row-2-column {
    display: flex;
    flex: 1;
    line-height: 21px;
}

.cart-row-2-column .cart-value-percent {
    line-height: 22px;
}

.cart-row-2-column > * {
    flex: 50%;
}

.status-bar {
    border: 1px solid #078144;
    border-radius: 5px;
    display: flex;
    padding: 0 9px;
}

.status-flex {
    flex: 1;
}

.status-bar > div {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    flex: 1 1 25px;
    margin: 7px 9px;
}

.cart-row-column {
    line-height: 28px;
}

.cart-row-column .cart-value-percent {
    line-height: 25px;
}

.cart-row.full-size .cart-text {
    line-height: 25px;
}

.title-pointer {
    cursor: pointer;
}
