.form-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
}

.form-label, .form-input {
    flex-direction: column;
    flex: 1;
}

.form-label {
    padding: 0 10px 0 0;
    flex-basis: calc(35% - 10px);
    text-align: right;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 16px;
}

.form-input {
    flex-basis: 65%;
}

.action-button {
    display: flex;
    margin-left: 35%;
}

.action-button button {
    width: inherit;
    margin-right: 5px;
}