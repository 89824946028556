@media (max-width: 850px) {
    .top-bar, .content {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

.container {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 14px;
    display: grid;
    grid-template-columns: 272px 1fr;
    max-width: 1633px;
    margin: 0 auto;
    min-height: 100vh;
}

@media (min-width: 1633px) {
    .container {
        border-left: 1px solid #DAE9BF;
        border-right: 1px solid #DAE9BF;
        box-shadow: 0px 10px 70px rgba(100, 37, 254, 0.04); 
    }
}

.left-column {
    position: relative;
    background-color: rgba(218, 233, 191, 0.5);
}

.right-column {
    background: rgba(218, 233, 191, 0.2);
    position: relative;
}

.logo {
    background-image: url("../../../public/images/logo.svg");
    width: 125px;
    height: 125px;
    margin: 35px auto 0 auto;
}

.top-bar {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(146, 196, 71, 0.25);
}

.top-bar, .content {
    padding-left: 40px;
    padding-right: 40px;
}

.content {
    padding-top: 40px;
    padding-bottom: 70px;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 50px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
}

footer {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
}