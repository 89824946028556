
.progress-bar {
    background: rgba(146, 196, 71, 0.25);
    border-radius: 10px;
    width: 100%;
    height: 10px;
}

.progress-bar-value {
    background: #078144;
    border-radius: 10px;
    height: 10px;
}