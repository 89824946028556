
div.select {
    width: 100%;
    border: 0.2px solid #4B78B2;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

div.select > div {
    padding-top: 14px;
    padding-bottom: 14px;
}

.select-container {
    background-color: #FFFFFF;
}

.MuiOutlinedInput-notchedOutline{
    border: inherit;
}

input.not-valid {
    border: 1px solid #FD4233;
}

input.not-valid:focus{
    outline-color: #FD4233;  
    outline-style: solid;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: green;
}
.Component-cssNotchedOutline {
    border-color: #f0f;
}